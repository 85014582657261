const Linklistify = function(elemSelector, linksSelector, block){
  this.links = $(linksSelector)
  this.elem  = $(elemSelector)
  this.listHtml = $("<ul class='linklistify'></ul>")

  if(this.links.length > 0 && this.elem.length > 0){
    this.initialize(block)
  }
}

Linklistify.prototype.initialize = function(block){
  var that =  this
  this.links.each(function(index, elem){
    let text    = $(elem).text()
    let pageUrl = $(elem).attr("id")
    that.listHtml.append("<li><a href='#" + pageUrl +"' title='" + text + "'>" + text + "</a></li>")
  })
  this.elem.html(this.listHtml)
  block(this.listHtml)
}

export default Linklistify
