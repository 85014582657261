import './main.js'

import React from 'react'
import ReactDOM from 'react-dom'
import Linklistify from './modules/linklistify'

$(function(){
  var l = new Linklistify(".cheatsheet-content-list", ".cheatsheet-body h2", function(listHtml){
    $(".page-content h1").insertBefore(listHtml)
    listHtml.css({"margin-bottom" : "110px", "margin-top" : "-55px"})
  })
})
